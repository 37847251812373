import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/human-capital-usecase-details.scss'
import { FaAngleLeft } from "react-icons/fa"
import RouterImg from '../images/human-capital-usecase/router.png'

const ManagedServicesTheLouvre = () => {
    return (
        <div className="human-capital-usecase-details">
            <Layout>
                <Nav
                    isWhite={true}
                />

                <div className="px-5 pt-4">
                    <Link to="/human-capital-development-usecase"><p className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Human capital development</p></Link>
                </div>

                <div className="digital-usecase pb-5 pt-4">
                    <div className="bg-managed-louvre"></div>
                </div>


                <div className="container">
                    <h1 className="font-weight-bold text-center mt-md-5 big-text">Connecting our clients to competent IT geniuses to perform specialised services.</h1>

                    <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb"><span className="text-secondary mr-2">Managed services</span>Telecom</p>

                    <div style={{ borderBottom: '5px solid #E41700' }}></div>
                </div>

                <div className="py-5 my-5"></div>

                <div className="bg-client-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>The Client</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>Our clients were three top contenders in the African global market, each from different sectors — manufacturing, telecoms and government ports management. They each sought our help to connect them to an army of IT specialists within a wide range of technologies and skillsets to perform specialized services.</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>One of the three also sought an extra service — to manage their printing tasks across the organization.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-solution-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 mx-auto my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Our Solution</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                We engaged our networks of trained, ready IT geniuses and disbursed them in various onsite, near-site, and offsite offices within stipulated support models.
                                </p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                We also provided special printing services to one of our clients, to help them save cost on purchasing printers, papers and maintenance.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 mx-auto">
                            <img src={RouterImg} alt="Journey Map" className="img-fluid" />
                            <p className="text-secondary text-center mt-1">Hardware installation for client</p>
                        </div>
                    </div>
                </div>

                <div className="my-5 py-5"></div>

                <div className="bg-impact-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5">
                            <div className="col-md-6 my-5">
                                <p style={{ fontSize: '1.5rem' }} className="font-weight-bold">The Impact</p>

                                <ul>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Offered flexibility and scalability to these enterprises.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Managed Oracle databases for improved performance.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Boosted productivity and all-round deliverables within set timelines.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Managed every aspect of deployed and implementation to alleviate workload.</p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Ensured cost reduction and saving.</p></li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5 my-5">
                        <div className="col-md-5 mx-auto mb-5 text-center">
                            <Link to={`/contact`} state={{ path: 'human' }}><button style={{ height: '48px' }} class="btn btn-red h-100 btn-red-black px-4 no-border-radius">Get started</button></Link>
                        </div>
                    </div>
                </div>
            </Layout >
        </div>
    )
}

export default ManagedServicesTheLouvre
